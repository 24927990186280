import validator from "validator";
import { toast } from "react-toastify";
import moment from 'moment';

export const checkEmailFormate = (value) => {
  if (value) {
    return validator.isEmail(value);
  }
};

export function handleCopyToClipboard(value, type, t ) {
  navigator.clipboard.writeText(value);

  let toastMessage = t("copied");
  let toastId = null;

  switch (type) {
    case "doctor":
      toastMessage = t("Doctor's link copied");
      toastId = "doctor-toast";
      break;
    case "client":
      toastMessage = t("Client's link copied");
      toastId = "client-toast";
      break;
    case "guest":
      toastMessage = t("Guest's link copied");
      toastId = "guest-toast";
      break;
    case "zoom":
      toastMessage = "Password copied";
      toastId = "zoom-toast";
      break;
    default:
      toastMessage = "copied";
      toastId = "default-toast";
      break;
  }

  if (!toast.isActive(toastId)) {
    toast.success(toastMessage, { toastId });
  }
}

export const formatDate = (date) => {
  return moment(date).format('DD/MM/YY HH:mm');
};

export const debounce = (callback, delay) => {
  let timeout;

  return (...args) => {
    // Clear the existing timeout
    if (timeout) {
      clearTimeout(timeout);
    }

    // Set a new timeout
    timeout = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};


export function validateIsraeliID(id) {
	id = String(id).trim();
	if (id.length > 9 || isNaN(id)) return false;
	id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
		return Array.from(id, Number).reduce((counter, digit, i) => {
			const step = digit * ((i % 2) + 1);
			return counter + (step > 9 ? step - 9 : step);
		}) % 10 === 0;
}
