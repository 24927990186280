import React, { useEffect, useState, useCallback } from "react";
import { Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import catchErrors from "../../utils/catchErrors";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { MeetingPrice, adminGetUsers } from "../../config/service";
import { SelectDatepicker } from "react-select-datepicker";
import $ from "jquery";
import Select from "react-select";
import { handleKeyPress } from "../Login/login";
import { Network } from "../../config/axios";
import { checkEmailFormate, validateIsraeliID } from "../../utils/function";

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
  option: (provided) => ({
    ...provided,
    background: "transparent",
    color: "#000",
    fontWeight: "500",
    fontSize: "18px",
    padding: "10px 20px",
    lineHeight: "16px",
    cursor: "pointer",
    borderRadius: "0px",
    ":hover": {
      background: "#1E0F3C",
      borderRadius: "5px",
      color: "#E9E7EC",
    },
  }),
  control: () => ({
    background: "transparent",
    border: "1px solid #ced4da",
    borderRadius: "5px",
    color: "#000",
    display: "flex",
    alignItem: "center",
    height: "43px",
    margin: "0px",
    cursor: "pointer",
    fontSize: "18px",
    fontWeight: "500",
    ":hover": {
      background: "transparent",
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontWeight: "500",
    fontSize: "18px",
    color: "#000",
  }),
  input: (provided) => ({
    ...provided,
    color: "#000",
    fontWeight: "500",
    fontSize: "16px !important",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "2px 20px",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    paddingRight: "20px",
    color: "#858585c7",
  }),
};

const AdminConfirmation = ({
  formData,
  setformData,
  isPassport,
  setIsPassport,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  let lang = localStorage.getItem("lang");
  const token = localStorage.getItem("token");
  const idfMeeting =
    formData?.consult?.meetingGroupId &&
    formData?.consult?.meetingGroupId[0]?._id === "640ed7103f08dcee2d1f1719";
  const [priceamount, setPriceAmount] = useState();
  const [value, setValue] = useState();
  const [allusers, setAllUser] = useState([]);
  const [userid, setUserId] = useState();
  const [focus, setFocus] = useState(false);
  const [isExist, setIsExist] = useState(false);
  const [selectedUser, setSelectedUser] = useState();

  const map1 = new Map();
  map1.set("Morning", "בוקר");
  map1.set("Afternoon", "צהרים");
  map1.set("Evening", "ערב");
  useEffect(() => {
    getmeetingPrice();
    getUsers();
  }, []);
  const onDateChange = useCallback((date) => {
    setValue(date);
  }, []);

  const handleUserConfirmation = (e) => {
    const { name, checked } = e.target;
    if (e.target.value === "Select" || e.target.value === "בחר") {
      setformData((prev) => ({
        ...prev,
        confirmation: { ...prev.confirmation, [e.target.name]: "" },
      }));
    } else if (name === "passportNumber") {
      setIsPassport(!isPassport);
      setformData((prev) => ({
        ...prev,
        confirmation: { ...prev.confirmation, [e.target.name]: checked },
      }));
    } else {
      setformData((prev) => ({
        ...prev,
        confirmation: { ...prev.confirmation, [e.target.name]: e.target.value },
      }));
    }
  };

  const handleUserConfirmationPhone = (e) => {
    const { name, value } = e.target;
    if (
      value.startsWith("+") &&
      !isNaN(value.substring(1, value.length)) &&
      !value.endsWith(".")
    ) {
      setformData((prev) => ({
        ...prev,
        confirmation: { ...prev.confirmation, [name]: value.trim() },
      }));
    } else if (!isNaN(value) && !value.endsWith(".")) {
      setformData((prev) => ({
        ...prev,
        confirmation: { ...prev.confirmation, [name]: value.trim() },
      }));
    }
  };

  const getmeetingPrice = async () => {
    try {
      const response = await MeetingPrice();
      setPriceAmount(response.data?.defaultPrice);
    } catch (error) {
      console.log(error);
    }
  };
  console.log("priceamount", priceamount);
  const getUsers = async () => {
    let data = {
      page: 1,
      perPage: 1000,
      text: "",
    };

    try {
      const response = await adminGetUsers(data);
      if ([200, 201, 202].includes(response.status)) {
        setAllUser(
          response.data.user.filter((d) => {
            return d.role === "client";
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isExist) {
      if (!formData?.confirmation?.id) {
        toast.error(t("Please enter id number"));
        return;
      }
      if (!isPassport) {
        const isValidId = validateIsraeliID(formData?.confirmation?.id);

        if (!isValidId) {
          toast.error(t("The ID number you entered is invalid"));
          return;
        }
      }
      if (!formData?.confirmation?.first_name) {
        toast.error(t("Please enter first name"));
        return;
      }
      if (!formData?.confirmation?.last_name) {
        toast.error(t("Please enter last name"));
        return;
      }
      if (!formData?.confirmation?.phone) {
        toast.error(t("Please enter mobile number"));
        return;
      }
      if (!formData?.confirmation?.email) {
        toast.error(t("Please enter valid email"));
        return;
      }
      if (formData?.confirmation?.email) {
        const isEmailCorrect = checkEmailFormate(formData?.confirmation?.email);
        if (!isEmailCorrect) {
          toast.error("Please enter valid email");
          return;
        }
      }
      if (
        !formData?.confirmation?.gender &&
        formData?.consult?.meetingGroupId[0]?.name !== "IDF"
      ) {
        toast.error(t("Please select gender"));
        return;
      }
      if (idfMeeting && !formData?.confirmation?.personalId) {
        toast.error(t("Please enter Personal ID number"));
        return;
      }
      if (
        idfMeeting &&
        formData?.confirmation?.personalId.toString().length < 7
      ) {
        toast.error(t("Personal ID should contain at least 7 digits"));
        return;
      }
      if (!value && formData?.consult?.meetingGroupId[0]?.name !== "IDF") {
        toast.error(t("Please choose date of birth"));
        return;
      }
    }

    if (!isExist) {
      if (idfMeeting && selectedUser?.personalId.toString().length < 7) {
        toast.error(t("Please enter Personal ID number for this user"));
        return;
      }
    }

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    const collective = params.collective;
    if (collective) {
      formData["collective"] = collective;
    }
    meetingRequest();
  };
  const meetingRequest = async () => {
    try {
      const data = {
        requestedDates: formData.time,
        requestDescription: formData.reference,
        service: "video",
        consultingFieldId: formData.consult._id,
        isUrgent: false,
        age: formData?.age,
        issueInvoice: formData?.confirmation?.issueInvoice
          ? formData?.confirmation?.issueInvoice
          : false,
        meetingCreatorDetails: !isExist
          ? { id: userid.id }
          : {
              first_name: formData?.confirmation?.first_name
                ? formData?.confirmation?.first_name
                : "",
              last_name: formData?.confirmation?.last_name
                ? formData?.confirmation?.last_name
                : "",
              clientDOB: moment(value).toDate() ? moment(value).toDate() : "",
              id: formData?.confirmation?.id ? formData?.confirmation?.id : "",
              personalId: formData?.confirmation?.personalId
                ? formData?.confirmation?.personalId
                : "",
              clientGender: formData?.confirmation?.gender
                ? formData?.confirmation?.gender
                : "",
              userphone: formData?.confirmation?.phone
                ? formData?.confirmation?.phone
                : "",
              email: formData?.confirmation?.email
                ? formData?.confirmation?.email
                : "",
              UTM: "",
              collective: "",
              passportNumber: isPassport,
            },
      };
      if (formData.partnerId) {
        data.partnerId = formData.partnerId;
      }

      const response = await Network().post(
        "/admin/createAppointment",
        data,
        token && {
          headers: {
            authorization: token,
            "Content-Type": "Application/json",
          },
        }
      );
      const {
        data: { code, msg },
      } = response || {};
      if ([200, 201, 202].includes(code) || response.status == 201) {
        toast.success(t(msg));
        if (token) {
          navigate("/ViewMeeting");
        }
      } else {
        toast.error(t(msg));
      }
    } catch (error) {
      console.log(error);
      toast(t("An error occurred, please try again"));
      const errorMsg = catchErrors(error);
      console.log(errorMsg);
    }
  };

  const handleFilterUser = () => {
    const filterUserDetail = allusers.find((userId) => userId === userid);
    setSelectedUser(filterUserDetail);
  };

  $("#rsd__select-day option:first-child").text(t("Day"));
  $("#rsd__select-month option:first-child").text(t("Month"));
  $("#rsd__select-year option:first-child").text(t("Year"));

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    handleFilterUser();
  }, [userid]);

  const CustomToolTip = ({ id, children, title }) => (
    <OverlayTrigger
      // delay={{ hide: 40000 }}
      overlay={
        <Tooltip id={id} className="prescription-tooltip">
          {title}
        </Tooltip>
      }
    >
      <span className="tooltip-circle">{children}</span>
    </OverlayTrigger>
  );

  return (
    <div className="confirmation-details-section">
      <div className="section-heading confirmation">
        <h6>{t("Account Holder")}</h6>
      </div>
      <Form>
        <div
          className={`${"confirmation-agree-section"} ${
            lang === "EN" ? "textleft" : "textright"
          }`}
        >
          <>
            <Form.Check
              type="checkbox"
              label={t("Create new user")}
              className={lang === "EN" ? "checkleft" : "checkright"}
              checked={isExist}
              onChange={() => setIsExist(!isExist)}
            />
            {isExist ? (
              <div className="confirmation-input-section mb-3">
                <Form.Group className="form-group">
                  <Form.Label className={`id-label ${isPassport}`}>
                    {" "}
                    <p className={` ${isPassport ? "label-active" : ""}`}>
                      {" "}
                      {t("Id Number")}
                    </p>
                    <div
                      className={`slideThree ${isPassport} ${lang}`}
                      data-translation={
                        !isPassport ? t("Passport") : t("Id Number")
                      }
                    >
                      <input
                        type="checkbox"
                        value="None"
                        id="slideThree"
                        name="passportNumber"
                        checked={isPassport}
                        onChange={handleUserConfirmation}
                      />
                      <label for="slideThree"></label>
                    </div>
                    <p className={`${!isPassport ? "label-active" : ""}`}>
                      {" "}
                      {t("Passport")}
                    </p>
                  </Form.Label>
                  <Form.Control
                    className={"ltrForce"}
                    id="a"
                    type={isPassport ? t("text") : t("number")}
                    onWheel={(e) => e.target.blur()}
                    placeholder={isPassport ? t("Passport") : t("Id Number")}
                    name="id"
                    value={
                      formData?.confirmation?.id
                        ? formData?.confirmation?.id
                        : ""
                    }
                    onChange={handleUserConfirmation}
                    onKeyPress={handleKeyPress}
                    onInput={(e) =>
                      (e.target.value = e.target.value.slice(0, 14))
                    }
                    maxLength={!isPassport ? 9 : undefined}
                  />
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label> {t("First Name")} </Form.Label>
                  <Form.Control
                    id="a"
                    type="text"
                    placeholder={t("First Name")}
                    name="first_name"
                    value={
                      formData?.confirmation?.first_name
                        ? formData?.confirmation?.first_name
                        : ""
                    }
                    onChange={handleUserConfirmation}
                  />
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label> {t("Last Name")}</Form.Label>
                  <Form.Control
                    id="a"
                    type="text"
                    placeholder={t("Last Name")}
                    name="last_name"
                    value={
                      formData?.confirmation?.last_name
                        ? formData?.confirmation?.last_name
                        : ""
                    }
                    onChange={handleUserConfirmation}
                  />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label>
                    {" "}
                    {t("Phone Number")}{" "}
                    <CustomToolTip
                      title={t(
                        "An Israeli phone number should start with 05. For a foreign number, please start with + and enter the full number with the country code."
                      )}
                      id="phoneNumber"
                    >
                      {" "}
                      ?{" "}
                    </CustomToolTip>
                  </Form.Label>
                  <Form.Control
                    id="a"
                    type="text"
                    placeholder={t("Phone Number")}
                    name="phone"
                    className="phone_number"
                    value={
                      formData?.confirmation?.phone
                        ? formData?.confirmation?.phone
                        : ""
                    }
                    onChange={handleUserConfirmationPhone}
                  />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label> {t("Email")} </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder={t("Email")}
                    name="email"
                    value={
                      formData?.confirmation?.email
                        ? formData?.confirmation?.email
                        : ""
                    }
                    onChange={handleUserConfirmation}
                  />
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label> {t("Gender")}</Form.Label>
                  <div className="choose-gender-grid">
                    <Form.Check
                      type={"radio"}
                      label={t("Male")}
                      value={
                        formData?.confirmation?.gender
                          ? formData?.confirmation?.gender
                          : "Male"
                      }
                      name="gender"
                      onChange={handleUserConfirmation}
                    />
                    <Form.Check
                      type={"radio"}
                      label={t("Female")}
                      value={
                        formData?.confirmation?.gender
                          ? formData?.confirmation?.gender
                          : "Female"
                      }
                      name="gender"
                      onChange={handleUserConfirmation}
                    />
                  </div>
                </Form.Group>
                {formData?.consult?.meetingGroupId[0]?.name === "IDF" ? (
                  <Form.Group className="form-group">
                    <Form.Label>{t("Personal ID Number")}</Form.Label>
                    <Form.Control
                      className={"ltrForce"}
                      id="a"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      placeholder=""
                      name="personalId"
                      value={
                        formData?.confirmation?.personalId
                          ? formData?.confirmation?.personalId
                          : ""
                      }
                      onChange={handleUserConfirmation}
                      onKeyPress={handleKeyPress}
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 14))
                      }
                    />
                  </Form.Group>
                ) : null}
                <Form.Group>
                  <Form.Label> {t("Date of Birth")}</Form.Label>
                  <SelectDatepicker
                    order="day/month/year"
                    selectedDate={value}
                    onDateChange={onDateChange}
                    className="dob-picker"
                    monthPlaceholder={"Month"}
                  />
                </Form.Group>
              </div>
            ) : (
              <Form.Group className="form-group">
                <Form.Label>{t("Users list")}</Form.Label>

                <Select
                  options={allusers}
                  getOptionLabel={(option) =>
                    `${option.first_name} ${option.last_name} - ${option.id}`
                  }
                  getOptionValue={(option) => option.id}
                  onChange={(value) => (setUserId(value), setFocus(false))}
                  value={!focus ? userid : ""}
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                  blurInputOnSelect={true}
                  placeholder={t("Select a user")}
                  styles={customStyles}
                  classNamePrefix="prescription"
                />
              </Form.Group>
            )}
          </>
        </div>
        <div className="continue-btn">
          <Button type="submit" className="submit-btn" onClick={handleSubmit}>
            {" "}
            {t("Send a request for an appointment")}{" "}
          </Button>
          <Link to="/contact">{t("We will be happy to help")}</Link>
        </div>
      </Form>
    </div>
  );
};

export default AdminConfirmation;
